import React from "react";

function Info() {
  return (
    <div className="contact" id="contact-us">
      <h1 className="h2">Leave Us a Message</h1>
      <div className="heading-line"></div>
      <form
        action="https://formsubmit.co//bf427190c48af45d5e6ba92758f66680"
        method="POST"
      >
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          required
          className="input"
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          required
          className="input"
        />
        <textarea
          row="3"
          type="message"
          name="message"
          placeholder="Leave a message"
          required
          className="message"
        />

        <input type="hidden" name="_template" value="table" />
        <button type="submit" className="button1 mb-5">
          Send
        </button>
      </form>
    </div>
  );
}

export default Info;
