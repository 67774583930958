import React from "react";
import image1 from "../Components/img/ima7.jpg";
import image2 from "../Components/img/image2.jpg";
import new2 from "../Components/img/new2.jpeg";
import new4 from "../Components/img/new4.jpeg";
import new3 from "../Components/img/new3.jpeg";
import image6 from "../Components/img/image6.jpg";

const Service = () => {
  return (
    <div className="service mt-5" id="service">
      <div className="service-container1">
        <div>
          <h1 className="service-title fw-bolder">Our Services</h1>
          <div className="heading-line"></div>
          <div className="sev-tit">
            We provide service in the following areas:
          </div>
        </div>
        <ul>
          <li className="section-li">
            <div className="section-rounded"></div>Material handling equipment
            sales and after sales services
          </li>
          <li className="section-li">
            <div className="section-rounded"></div>Warehouse equipment and
            maintenance planner
          </li>
          <li className="section-li">
            <div className="section-rounded"></div>Field services support
          </li>
          <li className="section-li">
            <div className="section-rounded"></div> Warehouse/engineering
            consult
          </li>
        </ul>
      </div>
      <div className="service-container2">
        <img src={image1} alt="hollarams" className="service-img" />
        <img src={image2} alt="hollarams" className="service-img" />
        <img src={new2} alt="hollarams" className="service-img" />
        <img src={new4} alt="hollarams" className="service-img" />
        <img src={new3} alt="hollarams" className="service-img" />
        <img src={image6} alt="hollarams" className="service-img" />
      </div>
    </div>
  );
};

export default Service;
