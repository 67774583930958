import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../Components/img/logo.png";
import { socialMedia } from "./socialmedia";

function OffcanvasExample() {
  return (
    <div className="navClass">
      {["md"].map((expand) => (
        <Navbar
          key={expand}
          bg="blue"
          expand={expand}
          className="mb-3 shadow sticky tg"
        >
          <Container fluid>
            <Navbar.Brand href="/" className="name-title">
              <div className="name-title">
                <img src={logo} alt="no img" className="logo" />
              </div>{" "}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                ></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="#about">
                    <div className="nav-link">About Us</div>
                  </Nav.Link>
                  <Nav.Link href="#service">
                    <div className="nav-link">Our Service</div>
                  </Nav.Link>
                  <Nav.Link href="#team">
                    <div className="nav-link">Team</div>
                  </Nav.Link>
                  <NavDropdown
                    className="nav-link"
                    title="Contact us"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href={socialMedia.whatsapp}>
                      <div className="nav-link">Whatsapp</div>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <div
                        className="nav-link"
                        onClick={() =>
                          (window.location = `mailto:${socialMedia.gmail}`)
                        }
                      >
                        Email
                      </div>
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </div>
  );
}

export default OffcanvasExample;
