import React from "react";
import { AutoPlay } from "./Slider";

export default function LatestProjects() {
  return (
    <>
      <div className="latest-project">
        <div className="lp fw-bolder">Equipment Sales & Service Focus</div>
        <div className="heading-line"></div>
        <div className="autoplay mt-5">
          <AutoPlay />
        </div>
        <div className="lp-last">
          <p className="lp-p">Our Sales / Procurement Portfolio </p>
          <div>
            <ul className="list">
              <li className="section-li lm">
                <div className="section-rounded"></div>Hand Pallet Trucks &
                Spare parts
              </li>

              <li className="section-li lm">
                <div className="section-rounded"></div>Batteries, Charger &
                Accessories
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Distilled Water
                Generating Device
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Engine Parts
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Exhaust Systems
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Bearings
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Fuse & Fuse Holders
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Chains & Accessories
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Hydraulic System Parts
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Key Switches
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Starters and Alternators
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Tyres, Rims & Accessories
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div> Wheel Brake & Master
                Cylinders
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div> Wheel & Rollers
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>Forklift and heavy duty
                equipment, Stacker & Reach Truck Equipment Sales & Spare parts
              </li>
              <li className="section-li lm">
                <div className="section-rounded"></div>General Workshop Tools &
                Accessories ….. [OEM STANDARD]
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
