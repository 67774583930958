import {
  LinkedIn,
  Mail,
  MailOutline,
  Phone,
  Room,
  WhatsApp,
} from "@mui/icons-material";
import React from "react";
import { socialMedia } from "./socialmedia";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="one">
          <div>
            <h1 className="holls2">HOLLARAMS</h1>
            <p className="holls-p2 fw-bolder">Technical service limited</p>
          </div>
        </div>
        <div className="two">
          <h3>Contact us on any of our socials</h3>
          <div className="i-c">
            <a className="a-t" href={socialMedia.linkedin}>
              <div
                className="social-icon"
                style={{ backgroundColor: "#3B5999" }}
              >
                <LinkedIn />
              </div>
            </a>

            <a
              className="a-t"
              href="/"
              onClick={() => (window.location = `mailto:${socialMedia.gmail}`)}
            >
              <div
                className="social-icon"
                style={{ backgroundColor: "#E60023" }}
              >
                <Mail />
              </div>
            </a>
            <a className="a-t" href={socialMedia.whatsapp}>
              <div
                className="social-icon"
                style={{ backgroundColor: "#00E676" }}
              >
                <WhatsApp />
              </div>
            </a>
          </div>
        </div>
        <div className="three">
          <div>
            <Room className="pone" />
            7, Abigael Street, Alagbole Akute, Via Ojodu, Ogun State
          </div>

          <a className="tree" href={"tel:" + socialMedia.number}>
            <div>
              <Phone className="pone" />
              {socialMedia.number}
            </div>
          </a>

          <a
            className="tree"
            href="/"
            onClick={() => (window.location = `mailto:${socialMedia.gmail}`)}
          >
            <div>
              <MailOutline className="pone" /> {socialMedia.gmail}
            </div>
          </a>
        </div>
      </div>
      <div className="float">
        <a className="a-t" href={socialMedia.whatsapp}>
          <div className="social-icon1" style={{ backgroundColor: "#00E676" }}>
            <WhatsApp className="f" />
          </div>
        </a>
      </div>
      <div className="last"> 2022 © Hollarams. All Rights Reserved</div>
    </>
  );
}

export default Footer;
