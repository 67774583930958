import React from "react";
import Slider from "react-slick";
import ima1 from "../Components/img/ima1.jpg";
import ima2 from "../Components/img/ima2.jpg";
import ima3 from "../Components/img/ima3.jpg";
import ima4 from "../Components/img/ima4.jpg";
import new5 from "../Components/img/new5.jpeg";
import ima6 from "../Components/img/ima6.jpg";
import ima7 from "../Components/img/ima7.jpg";
import new6 from "../Components/img/new6.jpeg";
import new8 from "../Components/img/new8.jpg";

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 2000,
  cssEase: "linear",
  className: "center",
  centerMode: true,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const AutoPlay = () => {
  return (
    <div>
      <Slider {...settings}>
        <img src={ima1} alt="hollarams" className="img-fluid px-3 lp-img" />

        <img src={ima2} alt="hollarams" className="img-fluid  px-3 lp-img" />

        <img src={ima3} alt="hollarams" className="img-fluid  px-3 lp-img" />

        <img src={ima4} alt="hollarams" className="img-fluid  px-3 lp-img" />

        <img src={new8} alt="hollarams" className="img-fluid  px-3 lp-img" />

        <img src={ima6} alt="hollarams" className="img-fluid  px-3 lp-img" />
        <img src={ima7} alt="hollarams" className="img-fluid  px-3 lp-img" />
        <img src={new5} alt="hollarams" className="img-fluid  px-3 lp-img" />
        <img src={new6} alt="hollarams" className="img-fluid  px-3 lp-img" />
      </Slider>
    </div>
  );
};
