import { TipsAndUpdates } from "@mui/icons-material";
import React from "react";
import abt1 from "../Components/img/abt1.jpg";
import new1 from "../Components/img/new1.jpeg";
import abt3 from "../Components/img/abt3.jpg";
import img1 from "../Components/img/img1.jpg";
import { socialMedia } from "./socialmedia";

function About() {
  return (
    <div className="about" id="about">
      <div className="about-title">Overview Of Our Firm</div>
      <div className="heading-line"></div>

      <div className="container mb-5">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6">
            <p className="abt">
              HOLLARAMS TECHNICAL SERVICES LIMITED was established as a limited
              liability under the companies and allied matters act with the
              primary objectives of providing standard and quality engineering
              services.
              <br />
              <br />
              Such services include material handling equipment maintenance and
              control, warehouse equipment maintenance planning, lift trucks
              spare part supply [OEM STANDARD], equipment safety control, lift
              truck battery sales and maintenance, battery distill water
              generating equipment sales and installation (Electric and diesel
              forklift, reach truck, narrow axle truck, bending equipment,
              stackers, pallet trucks, among others), as well as general
              contracting procurement company.
            </p>
            <a href={"tel:" + socialMedia.number}>
              <button className="button">Contact Us</button>
            </a>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            <img src={img1} alt="/" className="about-picture-background" />
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row align-items-start">
          <div className="col-lg-4 mb-2">
            <div className="d-block text-center mb-3">
              <TipsAndUpdates fontSize="large" />
            </div>
            <p className="abt">
              We lay emphasis on timely delivery and high quality of product
              supplied. In our relative existence, we have handled quality
              service delivery and maintenance consultancy for organizations
              across the country.
            </p>
          </div>
          <div className="col-lg-4 mb-2">
            <div className="d-block text-center mb-3">
              <TipsAndUpdates fontSize="large" />
            </div>
            <p className="abt">
              We are structured to withstand the demand of timely quality
              service provision and are fully equipped with trained personnel to
              handle supply of product part and maintenance of various range of
              material handling equipment models
            </p>
          </div>
          <div className="col-lg-4 mb-2">
            <div className="d-block text-center mb-3">
              <TipsAndUpdates fontSize="large" />
            </div>
            <p className="abt">
              We have the technical experience to take on any maintenance job
              and spare parts supply within stipulated time. Our aim is to help
              minimize and control equipment downtime. We will be honored to
              work with your organization.
            </p>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row align-items-start justify-content-between">
          <div className="col-lg-4 mb-2 text-center">
            <img
              src={new1}
              alt="about hollarams"
              className="img-fluid down-image"
            />
          </div>
          
          <div className="col-lg-4 mb-2 text-center">
            <img
              src={abt3}
              alt="about hollarams"
              className="img-fluid down-image"
            />
          </div>
          
          <div className="col-lg-4 mb-2 text-center">
            <img
              src={abt1}
              alt="about hollarams"
              className="img-fluid down-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
