import React from "react";
import img1 from "../Components/img/img1.jpg";
import img2 from "../Components/img/img2.jpg";
import img3 from "../Components/img/img3.jpg";

const Section = () => {
  return (
    <div className="section-head">
      <div className="section-container1">
        <div className="section-title">
          <h1 className="holls">HOLLARAMS</h1>
          <p className="holls-p fw-bolder">Technical service limited</p>
        </div>
        <ul>
          <li className="section-li">
            <div className="section-rounded"></div>General Contractor
          </li>
          <li className="section-li">
            <div className="section-rounded"></div>Heavy Duty/Warehouse and
            Material Handling
          </li>
          <li className="section-li">
            <div className="section-rounded"></div>Equipment Sales and After
            Sales Operation
          </li>
          <li className="section-li">
            <div className="section-rounded"></div>Machinery and Industrial
            Equipment
          </li>
        </ul>
      </div>
      <div className="section-container2">
        <div style={{ display: "flex" }}>
          <div className="profile-picture">
            <img
              src={img2}
              alt="/"
              style={{ width: "200px", height: "200px", marginLeft: "30px" }}
              className="profile-picture-background ppg1"
            />
          </div>
          <div className="profile-picture1">
            <img
              src={img1}
              alt="/"
              style={{ width: "250px", height: "250px", marginLeft: "30px" }}
              className="profile-picture-background ppg2"
            />
          </div>
        </div>
        <div className="profile-picture3">
          <img
            src={img3}
            alt="/"
            style={{
              width: "180px",
              height: "181px",
              marginLeft: "5px",
              position: "absolute",
            }}
            className="profile-picture-background ppg3"
          />
        </div>
      </div>
    </div>
  );
};

export default Section;
