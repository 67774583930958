import "./App.css";
import About from "./Components/About";
import Footer from "./Components/Footer";
import Info from "./Components/Info";
import LatestProjects from "./Components/LatestProjects";
import Navbar from "./Components/Navbar";
import Section from "./Components/Section";
import Service from "./Components/Service";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Video from "./Components/Video";
function App() {
  return (
    <div>
      <Navbar />
      <Section />
      <About />
      <Service />
      <Video/>
      <LatestProjects />
      <Info />
      <Footer />
    </div>
  );
}

export default App;
