import React from "react";
import vid from "../Components/img/vid.mp4";

function Video() {
  return (
    <div className="video">
      <video src={vid} className="vids" controls="controls" autoplay="true" />
    </div>
  );
}

export default Video;
